import { PayloadAction, Slice, createSlice } from "@reduxjs/toolkit";
import { QuestionCategoryType } from "utilities/redux/types";


type CategoryDataType = {
  defaultCategories: QuestionCategoryType[];
};

const initialState: CategoryDataType = {
  defaultCategories: [],
};

export const CategorySlice: Slice<CategoryDataType> = createSlice({
  name: "categoryData",
  initialState,
  reducers: {
    storeDefaultCategories: (state, action: PayloadAction<QuestionCategoryType[]>) => {
      state.defaultCategories = action.payload;
    },
  },
});

export const { storeDefaultCategories } = CategorySlice.actions;

export default CategorySlice.reducer;
