import categories from "@/data/faq";

import { FAQCategoryType, FAQQuestionType, FAQType } from "utilities/types/StaticTypes";

export function isFAQQuestion(item: FAQQuestionType | FAQCategoryType): item is FAQQuestionType {
  return (item as FAQQuestionType)?.questions !== undefined;
}

function elementToString(element: string | JSX.Element): string {
  if (typeof element === "string") {
    return element;
  } else if (element?.props?.children) {
    return (Array.isArray(element?.props?.children) ? element.props.children : [element.props.children]).map(elementToString).join("") || "";
  } else {
    return "";
  }
}

export function getCategoriesAndQuestions(
  item: FAQCategoryType | FAQQuestionType,
  cats: Array<FAQType & { path: string[]; type: "topic" | "category" }> = [],
  paths = [],
  questions: Array<{ path: string[]; question: JSX.Element | string; answer: JSX.Element | string }> = []
) {
  const newPath = [...paths, item.value];

  cats.push({
    icon: item.icon,
    legend: item.legend,
    value: item.value,
    tags: item?.tags ?? [],
    path: newPath,
    type: "category",
  });

  if (isFAQQuestion(item)) {
    item.questions.forEach((subItem) =>
      questions.push({
        path: newPath,
        question: subItem.question,
        answer: elementToString(subItem?.answer),
      })
    );
  } else {
    item.items.forEach((subitem) => getCategoriesAndQuestions(subitem, cats, newPath, questions));
  }

  return { categories: cats, questions };
}

export function getCategoriesByPath(paths = [categories.value], item: FAQCategoryType = categories, breadcrumbs: Array<FAQType> = [categories]) {
  if (paths[0] === categories.value) {
    paths = paths.slice(1);
  }

  const pathsCopy = JSON.parse(JSON.stringify(paths));
  const value = pathsCopy.shift();
  const result = item.items.find((subitem) => subitem.value === value);
  breadcrumbs.push(result);

  if (!isFAQQuestion(result) && pathsCopy.length > 0) {
    return getCategoriesByPath(pathsCopy, result, breadcrumbs);
  } else {
    return { ...result, breadcrumbs };
  }
}
