import { EntryExerciceType, EntryGraphAccountTypeType, EntryGraphType } from "../../../../utilities/redux/types";
import { diffDays } from "../../../../utilities/tools/date";
import { BalancesType, GraphType } from "../AccountingChart";

export default function shapeData(data: Array<{
  exercice: EntryExerciceType,
  graphs: Array<{
    graph: EntryGraphType,
    values: Array<{
      type:
      EntryGraphAccountTypeType,
      balances: BalancesType[];
    }>;
  }>;
}>, maxNbExercice: number | null = null) {
  let graphs: GraphType[] = [];

  data?.forEach(item => {
    item.graphs.forEach(key => {
      const index = graphs.findIndex(g => g.id === key.graph.id);

      const exercice = { ...item.exercice, graph: key.graph };
      const newBalance = key.values.map(value => {
        return {
          ...value,
          exercice
        };
      });

      if (index !== -1) {
        graphs = [
          ...graphs.slice(0, index),
          {
            ...graphs[index],
            exercices: [...graphs[index].exercices, exercice]
              .sort((a, b) => diffDays(a.startAt, b.startAt)),
            balances: [...graphs[index].balances, ...newBalance]
              .sort((a, b) => diffDays(a.exercice.startAt) - diffDays(b.exercice.startAt))
          },
          ...graphs.slice(index + 1),
        ];
      }

      else {
        graphs.push({
          ...key.graph,
          balances: newBalance,
          exercices: [exercice]
        });
      }
    });
  });

  if (maxNbExercice) {
    graphs = graphs.map(g => {
      const exerciceStarts = [... new Set(g.exercices.map(e => e.startAt))];
      const keepedExerciceStart = exerciceStarts.slice(-maxNbExercice);

      return {
        ...g,
        exercices: g.exercices.filter(e => keepedExerciceStart.includes(e.startAt)),
        balances: g.balances.filter(b => keepedExerciceStart.includes(b.exercice.startAt))
      };
    });
  }


  return graphs.sort((a, b) => {
    if (a.graphType.organization && b.graphType.organization) {
      return a.graphType.organization - b.graphType.organization;
    } else if (a.graphType.organization) { return 1; } else { return -1; }
  });
}