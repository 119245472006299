import Link from "next/link";

export const company_create_faq = [
  {
    question: "Comment créer un nouveau dossier ?",
    answer: <>
      <p>Pour créer un nouveau dossier, vous devez vous rendre sur la page <Link className="link" href={`/dashboard/dossiers/ajout-dossier`}>Ajout dossier</Link> en cliquant sur le bouton <em>Créer nouveau dossier</em> situé sur la gauche, en dessous de la liste de dossier.</p>
      <p>Vous pouvez par la suite choisir l&apos;ajout manuel ou l&apos;importation grâce à une API.</p>
    </>,
  }, {
    question: "Comment ajouter un nouveau dossier manuellement ?",
    answer: <>
      <p>Pour ajouter un nouveau dossier manuellement, vous devez vous rendre sur la page <Link className="link" href={`/dashboard/dossiers/ajout-dossier/manuellement`}>Ajouter un dossier</Link> en cliquant sur le bouton <em>Créer nouveau dossier</em> situé sur la gauche, en dessous de la liste de dossier.</p>
      <p>Vous devez renseigner les données du dossier et cliquer sur <em>Suivant</em>. Vous pourrez par la suite ajouter des utilisateurs au dossier.</p>
    </>,
  }, {
    question: "Comment importer un nouveau dossier d'une API?",
    answer: <>
      <p>Pour ajouter un nouveau dossier, vous devez vous rendre sur la page <Link className="link" href={`/dashboard/dossiers/ajout-dossier/via-api`}>Importation dossier</Link> en cliquant sur le bouton <em>Créer nouveau dossier</em> situé sur la gauche, en dessous de la liste de dossier.</p>
      <p>Vous devez ensuite choisir l&apos;API par laquelle vous voulez importer le(s) dossier(s). L&apos;API Tiime ne propose pas d&apos;importation de dossier.</p>
    </>,
  },
];
