import { createSlice, PayloadAction, Slice } from "@reduxjs/toolkit";

export type toastType = {
  id: number;
  legend: string;
  type: "good" | "fail" | "warning" | "info";
};

export type toastState = {
  toasts: toastType[];
};

const initialState: toastState = {
  toasts: [],
};

export const toastSlice: Slice<toastState> = createSlice({
  name: "toastData",
  initialState,

  reducers: {
    addToast: (state, action: PayloadAction<toastType>) => {
      state.toasts = [
        ...state.toasts,
        {
          legend: action.payload.legend,
          type: action.payload.type,
          id: Math.random(),
        },
      ];
    },
    closeToast: (state, action: PayloadAction) => {
      if (state.toasts.length == 1) {
        state.toasts = [];
      } else {
        state.toasts = state.toasts.filter((toast: any) => toast.id !== action.payload);
      }
    },
  },
});
// Here we are just exporting the actions from this slice, so that we can call them anywhere in our app.
export const { addToast, closeToast } = toastSlice.actions;

export default toastSlice.reducer;
