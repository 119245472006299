export const acd_faq = [
  {
    question: 'J\'ai cette erreur "Une erreur est survenue lors de la connexion à iSuiteExpert. Veuillez réessayer plus tard.", que faire ?',
    answer: (
      <div>
        <p>Cette erreur survient lorsque la configuration pour ACD n&apos;est pas correcte, vérifiez que les champs cnx et url sont bien renseignées.</p>
        <p>
          En général, la valeur du champs CNX est tout simplement &quot;CNX&quot;, mais elle peut dépendre de votre installation ACD.
          <br /> Rapprochez-vous de votre installateur ACD pour toute précision à ce sujet.
        </p>
      </div>
    ),
  },
  {
    question: "iSuiteExpert n'a pas pu nous fournir les écritures de ce dossier. Veuillez réessayer plus tard.",
    answer: (
      <div>
        <p>Veuillez vérifiez que vos dossiers sont correctement partagés au niveau d&apos;ACD, par défault, ils ne le sont pas</p>
      </div>
    ),
  },
];
