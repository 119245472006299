import { createSlice, PayloadAction, Slice } from "@reduxjs/toolkit";
import { AccountingFirmBillingOptionType, PricingNatureType } from "utilities/redux/types";

export type BillingOptionSliceType = {
  myOptions: AccountingFirmBillingOptionType[];
  optionsAvailables: PricingNatureType[];
  optionSelected: PricingNatureType;
  myOptionLinkedToSelected: AccountingFirmBillingOptionType;
  hasSubscribed: boolean;
};

const initialState: BillingOptionSliceType = {
  myOptions: [],
  optionsAvailables: [],
  optionSelected: null,
  hasSubscribed: false,
  myOptionLinkedToSelected: null,
};

export const BillingOptionSlice: Slice<BillingOptionSliceType> = createSlice({
  name: "billingOptionData",
  initialState,

  reducers: {
    storeOptionsAvailable: (state, action: PayloadAction<PricingNatureType[]>) => {
      state.optionsAvailables = action.payload;
    },
    storeMyOptions: (state, action: PayloadAction<AccountingFirmBillingOptionType[]>) => {
      state.myOptions = action.payload;
    },
    setOptionSelected: (state, action: PayloadAction<PricingNatureType>) => {
      state.optionSelected = action.payload;
    },
    setHasSubscribedToSelectedOption: (state, action: PayloadAction<boolean>) => {
      state.hasSubscribed = action.payload;
    },
    setMyOptionLinkedToSelected: (state, action: PayloadAction<AccountingFirmBillingOptionType>) => {
      state.myOptionLinkedToSelected = action.payload;
    },
  },
});
// Here we are just exporting the actions from this slice, so that we can call them anywhere in our app.
export const { storeMyOptions, setOptionSelected, storeOptionsAvailable, setMyOptionLinkedToSelected, setHasSubscribedToSelectedOption } =
  BillingOptionSlice.actions;

export default BillingOptionSlice.reducer;
