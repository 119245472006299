const cycle_faq = [{
  question: "Que sont les questions à valider ?",
  answer: <>
    <p>Ces questions correspondent en premier aux questions créées par l&apos;algorithme, en second à celle créées par les collaborateurs.</p>
    <p>Vous pouvez modifier les questions, y ajouter des pièces jointes.</p>
  </>
}, {
  question: "Que sont les questions à envoyer ?",
  answer: <>
    <p>Ces questions correspondent qui ont été validées par les collaborateurs et sont en attente d&apos;envoi aux clients.</p>
  </>
}, {
  question: "Que sont les questions en attente ?",
  answer: <>
    <p>Ces questions correspondent qui ont été envoyées par les collaborateurs et sont en attente de réponses des clients.</p>
  </>
}, {
  question: "Que sont les questions à traiter ?",
  answer: <>
    <p>Ces questions correspondent qui ont été envoyées par les clients et sont en attente de validation des collaborateurs.</p>
  </>
}, {
  question: "Que sont les questions traitées ?",
  answer: <>
    <p>Ces questions correspondent qui ont été traitées par les collaborateurs, vous pouvez y retrouver les pièces jointes envoyées par les clients.</p>
  </>
}, {
  question: "Que sont les questions archivées ?",
  answer: <>
    <p>Ces questions correspondent qui ont été archivées par les collaborateurs et sont en attente de suppression définitive.</p>
  </>
},
];

export default cycle_faq;