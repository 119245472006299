const company_informations_faq =
  [{
    question: "Comment modifier le logo d'un dossier ?",
    answer: <>
      <p>Pour modifier le logo d&apos;un dossier, vous devez vous rendre sur la page de l&apos;entreprise en question et cliquer sur l&apos;onglet <em>Paramètres</em> puis <em>Informations</em>. </p>
      <p>Arrivé sur la page, vous pouvez cliquer sur le logo pour choisir une nouvelle image.</p>
    </>,
  }, {
    question: "Comment modifier les informations",
    answer: <p>Pour modifier les informations d&apos;Sun dossier, vous devez vous rendre sur la page de l&apos;entreprise en question et cliquer sur l&apos;onglet <em>Paramètres</em> puis <em>Informations</em>.</p>,
  },
  ];

export default company_informations_faq;