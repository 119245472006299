import Link from "next/link";

const connexion_faq = [
  {
    question: "Comment accéder aux deux interfaces cabinet et entreprise ?",
    answer: <p>Vous ne pouvez pas utiliser le même mail pour plusieurs interfaces. Vous allez donc devoir créer plusieurs comptes avec des adresses mails différentes</p>,
  },
  {
    question: "Je n'ai pas reçu le mail d'invitation, que faire?",
    answer: <>
      <p>Pensez à regarder dans vos spams.</p>
      <br />
      <p>Si vous n&apos;avez effectivement rien dans vos spams: </p>
      <ul>
        <li>Vous pouvez tout de même vous rendre sur la bonne interface, et cliquez sur <em>Mot de passe oublié</em> pour créer votre mot de passe.</li>
        <li>Si vous ne savez pas sur quelle interface vous rendre, demandez à la personne qui vous a ajouté de vous renvoyer le mail de création de compte.</li>
      </ul>
    </>,
  },
  {
    question: "L'utilisateur n'a pas reçu le mail d'invitation, que faire?",
    answer: <>
      <p>Vous pouvez renvoyer le mail d&apos;invitation pour les différents types d&apos;utilisateur: </p>
      <ul>
        <li>Pour un collaborateur, vous devez vous rendre dans l&apos;onglet <Link className="link" href={`/dashboard/gestion-cabinet/collaborateurs`}>Collaborateurs</Link>, sélectionner l&apos;utlisateur désiré et cliquer sur le bouton <em>Renvoyez mail invitation</em></li>
        <li>Pour un utilisateur entreprise, vous devez vous rendre sur la page du dossier désiré, dans l&apos;onglet <em>Paramètre</em> puis <em>Utilsateurs</em>, sélectionner l&apos;utlisateur désiré et cliquer sur le bouton <em>Renvoyez mail invitation</em></li>
      </ul>
    </>,
  },
];

export default connexion_faq;