export const demo_faq = [
  {
    question: "Que se passe-t-il à la fin d'une période d'essai sur Syndemos ? ",
    answer: (
      <p>
        Pour le moment seul le <strong>prélèvement SEPA</strong> est disponible.
      </p>
    ),
  },
];
