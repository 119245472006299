import { BsFilterLeft } from "ui/react-icons/bs";
import commercial from "../categories/commercial/commercial";
import technique from "../categories/technical/technique";

const categories = {
  legend: "Toutes les catégories",
  value: "all",
  icon: <BsFilterLeft />,
  items: [commercial, technique],
};

export default categories;
