import { AccountType } from "utilities/redux/types";

export const sortAccountsByDirectionAndDomain = (
  accounts: AccountType[],
  direction: "DESC" | "ASC" | "",
  domain: "code" | "legend" | "",
): AccountType[] => {
  if (direction === "DESC") {
    /// SORTING ACCOUNTS
    if (domain == "code") {
      accounts.sort((a: any, b: any) => b.code.localeCompare(a.code));
    }
    if (domain == "legend") {
      accounts.sort((a: any, b: any) => b.legend.localeCompare(a.legend));
    }
  }
  if (direction === "ASC") {
    /// SORTING ACCOUNTS
    if (domain == "code") {
      accounts.sort((a: any, b: any) => a.code.localeCompare(b.code));
    }
    if (domain == "legend") {
      accounts.sort((a: any, b: any) => a.legend.localeCompare(b.legend));
    }
  }
  return accounts;
};
