import { createSlice, PayloadAction, Slice } from "@reduxjs/toolkit";
import { FAQCategoryType, FAQQuestionType, FAQType } from "utilities/types/StaticTypes";
import { isFAQQuestion } from "@/lib/statics/faq";
import faq from "@/data/faq";

export type DocumentationSlice = {
  breadcrumbs: Array<FAQType>;
  category: FAQQuestionType;
};

const initialState: DocumentationSlice = {
  breadcrumbs: [faq],
  category: null
};

export const StyleSlice: Slice<DocumentationSlice> = createSlice({
  name: "SupportData",
  initialState,

  reducers: {
    setCategory: (state, action: PayloadAction<(FAQQuestionType | FAQCategoryType) & { breadcrumbs: FAQType[]; }>) => {

      if (isFAQQuestion(action.payload)) {
        state.breadcrumbs = action.payload.breadcrumbs;
        state.category = action.payload;
      } else {
        delete action.payload.items;
        state.category = null;
        state.breadcrumbs = action.payload.breadcrumbs;
      }
    },

    onBreadcrumbClick: (state, action: PayloadAction<number>) => {
      state.category = null;
      state.breadcrumbs = state.breadcrumbs.slice(0, action.payload + 1);
    }

  },
});
// Here we are just exporting the actions from this slice, so that we can call them anywhere in our app.
export const {
  setCategory,
  onBreadcrumbClick,
} =
  StyleSlice.actions;

export default StyleSlice.reducer;
