import { CompanyType } from "utilities/redux/types";
import { createSlice, PayloadAction, Slice } from "@reduxjs/toolkit";
import { CriteriaType } from "ui/components/inputs";

export type RapidEntrySliceType = {
  file: File | null;
  attachmentState: "creation" | "search";
  search: string;
  autoSearchResult: CompanyType | null;
  isImporting: boolean;
  isAutoSearching: boolean;
  companySelected: CompanyType | null;
  searchedCompanies: CompanyType[];
  searchedCompanySelected: CompanyType | null;
  selectedCriteria: CriteriaType | null;
};

const initialState: RapidEntrySliceType = {
  file: null,
  attachmentState: "search",
  search: "",
  selectedCriteria: null,
  autoSearchResult: null,

  // CREATION COMPANY
  isImporting: false,
  isAutoSearching: false,
  companySelected: null,
  searchedCompanies: [],
  searchedCompanySelected: null,
};

export const rapidEntrySlice: Slice<RapidEntrySliceType> = createSlice({
  name: "rapidEntryData",
  initialState,

  reducers: {
    setFile: (state, action: PayloadAction<File>) => {
      state.file = action.payload;
    },
    setIsImporting: (state, action: PayloadAction<boolean>) => {
      state.isImporting = action.payload;
    },

    /// ATTACHMENT ///// ------------------------------
    setAttachmentState: (state, action: PayloadAction<"search" | "creation">) => {
      state.attachmentState = action.payload;
    },

    /////// SEARCH
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    setSelectedCriteria: (state, action: PayloadAction<CriteriaType>) => {
      state.selectedCriteria = action.payload;
    },
    setSearchedCompanies: (state, action: PayloadAction<CompanyType[]>) => {
      state.searchedCompanies = action.payload;
    },
    setSearchedCompanySelected: (state, action: PayloadAction<CompanyType>) => {
      state.searchedCompanySelected = action.payload;
    },

    /////// COMPANY AUTO-SEARCH BY SIREN
    setIsAutoSearching: (state, action: PayloadAction<boolean>) => {
      state.isAutoSearching = action.payload;
    },
    setAutoSearchedSirenResult: (state, action: PayloadAction<CompanyType>) => {
      state.autoSearchResult = action.payload;
    },
  },
});

export const {
  setFile,
  setIsImporting,
  setAttachmentState,
  setSearch,
  setSelectedCriteria,
  setSearchedCompanySelected,
  setIsAutoSearching,
  setAutoSearchedSirenResult,
  setSearchedCompanies,
} = rapidEntrySlice.actions;

export default rapidEntrySlice.reducer;
