import { CompanyFromACDType } from "utilities/redux/types";
import { createSlice, PayloadAction, Slice } from "@reduxjs/toolkit";
import { CriteriaType } from "ui/components/inputs";
import { paginateData } from "utilities/tools/array";

export type ImportACDCompanyType = {
  //// ACD
  companies: CompanyFromACDType[] | [];
  allcompanies: CompanyFromACDType[] | [];
  companiesSelected: CompanyFromACDType[] | [];
  searchItem: CompanyFromACDType | null;
  searchTerms: string;
  criteria: CriteriaType | null;
  itemsPerPage: number;
  countPages: number;
  currentPage: number;
  nbrPages: number;
  step: number;
};

const initialState: ImportACDCompanyType = {
  //// ACD STEP
  step: 0,
  // COMPANIES
  allcompanies: [],
  companies: [],
  companiesSelected: [],
  // -> pagination
  itemsPerPage: 100,
  countPages: 1,
  currentPage: 1,
  nbrPages: 1,
  // SEARCH
  searchTerms: "",
  searchItem: null,
  criteria: null,
};

export const ImportCompanyFromAcd: Slice<ImportACDCompanyType> = createSlice({
  name: "importCompanyACDData",
  initialState,
  reducers: {
    ///STEPS
    setStep: (state, action: PayloadAction<number>) => {
      state.step = action.payload;
    },

    /// COMPANIES
    storeCompanies: (state, action: PayloadAction<any[]>) => { },
    storeAllCompanies: (state, action: PayloadAction<CompanyFromACDType[]>) => {
      state.allcompanies = action.payload;

      let nonSelectedCompanies = action.payload.filter(
        //
        (item: any) => !state.companiesSelected.some((truc2: CompanyFromACDType) => truc2.Code === item.Code)
      );

      let paginated = paginateData(nonSelectedCompanies, state.itemsPerPage, state.currentPage);

      state.companies = paginated.paginatedData;
      state.countPages = paginated.nbrOfPages;
      if (paginated.nbrOfPages === 1) {
        state.currentPage = 1;
      }

      if (paginated.nbrOfPages < state.currentPage) {
        state.currentPage = paginated.nbrOfPages;
      }
    },

    selectCompanies: (state, action: PayloadAction<CompanyFromACDType>) => {
      let copy = JSON.parse(JSON.stringify(state.companiesSelected));

      if (state.companiesSelected.some((comp: CompanyFromACDType) => comp.Code == action.payload.Code)) {
        ////

        ////////////// A. FILTER IT
        let result = copy.filter((item: CompanyFromACDType) => item.Code !== action.payload.Code);
        state.companiesSelected = result;

        let diff1 = state.allcompanies.filter(
          (item1: CompanyFromACDType) => !result.some((item2: any) => item1.Code === item2.Code)
        );

        // and add into companies
        let paginated = paginateData(diff1, state.itemsPerPage, state.currentPage);
        state.companies = paginated?.paginatedData;
        state.countPages = paginated?.nbrOfPages;
      } else {
        ////

        /////////////  B. NOT IN ARRAY  ->ADD IT
        let copy2 = JSON.parse(JSON.stringify(state.companiesSelected));
        let newSelectedValues = [...copy2, action.payload];
        state.companiesSelected = newSelectedValues;
        // and remove into companies
        let copyFromCompanies = JSON.parse(JSON.stringify(state.allcompanies));
        let result = copyFromCompanies.filter(
          (item: any) => !newSelectedValues.some(item2 => item["Code"] === item2["Code"])
        );
        if (state.companies?.length <= 1) {
          state.currentPage = state.currentPage - 1;
          //
        }

        let paginated = paginateData(result, state.itemsPerPage, state.currentPage);
        state.companies = paginated?.paginatedData;
        state.countPages = paginated?.nbrOfPages;
      }
    },
    updateRestrictionCompany: (state, action: PayloadAction<{ code: string; restricted: boolean; }>) => {
      const copy = JSON.parse(JSON.stringify(state.companiesSelected));
      copy.find((item: CompanyFromACDType) => item.Code === action.payload.code).restricted = action.payload.restricted;
      state.companiesSelected = copy;
    },

    resetACDCompanySelected: (state, action: PayloadAction<null>) => {
      state.companiesSelected = [];
      let paginated = paginateData(state.allcompanies, state.itemsPerPage, state.currentPage);

      state.companies = paginated.paginatedData;
      state.countPages = paginated.nbrOfPages;
    },
    /// -> PAGINATION

    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
      let paginated = paginateData(state.allcompanies, state.itemsPerPage, action.payload);
      state.companies = paginated.paginatedData;
      state.countPages = paginated.nbrOfPages;
    },
    setCountPages: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },

    ///// SEARCH BY CODE
    setSearchTerm: (state, action: PayloadAction<string>) => {
      state.searchTerms = action.payload;
    },
    setCriteria: (state, action: PayloadAction<CriteriaType>) => {
      state.criteria = action.payload;
    },

    setSearchItem: (state, action: PayloadAction<CompanyFromACDType>) => {
      state.searchItem = action.payload;
    },
  },
});

export const {
  storeAllCompanies,
  storeCompanies,
  selectCompanies,
  setStep,
  setCurrentPage,
  setCountPages,
  updateRestrictionCompany,
  resetACDCompanySelected,
  setSearchItem,
  setSearchTerm,
  setCriteria,
} = ImportCompanyFromAcd.actions;

export default ImportCompanyFromAcd.reducer;
