import { AccountingFirmConnectorTypeType } from "utilities/redux/types";
import { createSlice, PayloadAction, Slice } from "@reduxjs/toolkit";

export type ImportCompanyType = {
  ////  CONNECTOR
  connectorSelected: AccountingFirmConnectorTypeType | null;
};

const initialState: ImportCompanyType = {
  //  CONNECTOR
  connectorSelected: null,
};

export const ImportCompanyFromApi: Slice<ImportCompanyType> = createSlice({
  name: "importCompanyData",
  initialState,

  reducers: {
    /// CONNECTORS
    setConnectorSelected: (state, action: PayloadAction<AccountingFirmConnectorTypeType>) => {
      state.connectorSelected = action.payload;
    },
  },
});
// Here we are just exporting the actions from this slice, so that we can call them anywhere in our app.
export const { setConnectorSelected } = ImportCompanyFromApi.actions;

export default ImportCompanyFromApi.reducer;
