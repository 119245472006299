import Link from "next/link";

const accountingFirm_informations_faq = [{
  question: "Comment modifier le logo cabinet ?",
  answer: <>
    <p>Pour modifier le logo du cabinet, vous devez vous rendre sur la page du cabinet sur  <Link className="link" href={`/dashboard/gestion-cabinet`}>son nom</Link></p>
    <p>Arrivé sur la page, vous pouvez cliquer sur le logo pour choisir une nouvelle image.</p>
  </>,
}, {
  question: "Comment modifier les informations",
  answer: <p>Pour modifier les informations d&u cabinet, vous devez vous rendre sur la page du cabinet sur  <Link className="link" href={`/dashboard/gestion-cabinet`}>son nom</Link>.</p>,
},];

export default accountingFirm_informations_faq;