import Link from "next/link";

const accountingFirm_employees_faq = [
  {
    question: "Comment ajouter un compte expert à mon cabinet ?",

    answer: (
      <p>
        Si vous avez un compte expert dirigeant, vous pouvez ajouter des collaborateurs en vous rendant dans l&apos;onglet{" "}
        <Link className="link" href={`/dashboard/gestion-cabinet/collaborateurs`}>
          Collaborateurs
        </Link>
        .
      </p>
    ),
  },
  {
    question: "Comment affecter un collaborateur à un dossier ?",
    answer: (
      <>
        <p>
          Si vous avez un compte expert dirigeant, vous pouvez affecter des dossier à un collaborateur en vous rendant dans l&apos;onglet{" "}
          <Link className="link" href={`/dashboard/gestion-cabinet/collaborateurs`}>
            Collaborateurs
          </Link>
          , dans la partie <em>Dossiers affectés</em>.
        </p>
        <p>
          Vous pouvez également vous rendre sur la page du dossier et l&apos;ajouter via la partie <em>Affectations des comptables pour ce dossier</em>
        </p>
      </>
    ),
  },
  {
    question: "Comment changer le rôle d'un collaborateur ?",
    answer: (
      <p>
        Vous devez être dirigeant d&apos;un cabinet pour pouvoir modifier le rôle d&apos;un collaborateur. Vous devez vous rendre dans l&apos;onglet{" "}
        <Link className="link" href={`/dashboard/gestion-cabinet/collaborateurs`}>
          Collaborateurs
        </Link>
        , dans la partie <em>Gestion du collaborateur</em> et sélectionner le rôle voulu.
      </p>
    ),
  },
  {
    question: "Le collaborateur n'a pas reçu le mail d'invitation, que faire?",
    answer: (
      <p>
        Vous devez vous rendre dans l&apos;onglet{" "}
        <Link className="link" href={`/dashboard/gestion-cabinet/collaborateurs`}>
          Collaborateurs
        </Link>
        , sélectionner l&apos;utlisateur désiré et cliquer sur le bouton <em>Renvoyez mail invitation</em>.
      </p>
    ),
  },
];

export default accountingFirm_employees_faq;
