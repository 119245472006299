import notificationUserSlice from "./slices/notification/NotificationUserSlice";
import ImportCompanyFromApiSlice from "./slices/ImportCompanyFromApiSlice";
import { Action, combineReducers, configureStore, ThunkAction } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
// import { encryptTransform } from "redux-persist-transform-encrypt";

//// SLICES
import toastReducer from "./slices/ToastSlice";
import userReducer from "./slices/UserSlice";
import styleReducer from "./slices/StyleSlice";
import entryImportSlice from "./slices/EntryImportSlice";
import CompanySlice from "./slices/CompanySlice";
import AccountingFirmSlice from "./slices/AccountingFirmSlice";
import BillingReducer from "./slices/BillingSlice";
// historic
import companyHistoricSlice from "./slices/historic/CompanyHistoricSlice";
import accountingFirmHistoricSlice from "./slices/historic/AccountingFirmHistoricSlice";

/// questions
import questionToValidatedSlice from "./slices/question/QuestionToValidatedSlice";
import questionToSendSlice from "./slices/question/QuestionToSendSlice";
import QuestionToProcessSlice from "./slices/question/QuestionToProcessSlice";
import QuestionProcessedSlice from "./slices/question/QuestionProcessedSlice";
import questionArchivedSlice from "./slices/question/QuestionArchivedSlice";
import CategorySlice from "./slices/CategoriesSlice";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import QuestionPendingSlice from "./slices/question/QuestionPendingSlice";
import questionExchangeSlice from "./slices/QuestionExchange";
import EntryImportHistorySlice from "./slices/EntryImportHistorySlice";
import invoiceSlice from "./slices/InvoiceSlice";
import rapidEntrySlice from "./slices/RapidEntryImportSlice";
import NavigationSlice from "./slices/NavigationSlice";
import AnswerSuggestionsSlice from "./slices/question/AnswerSuggestionsSlice";
import ImportCompanyFromQuadraSlice from "./slices/importCompanyFromApi/QuadraImportCompanyFromApi";
import ImportCompanyFromACDSlice from "./slices/importCompanyFromApi/AcdImportCompanyFromAPI";
import notificationCompanySlice from "./slices/notification/NotificationCompanySlice";

import TicketSlice from "./slices/support/TicketSlice";
import DocumentationSlice from "./slices/support/DocumentationSlice";
import userHistoricSlice from "./slices/historic/UserHistoricSlice";
import BillingOptionSlice from "./slices/BillingOptionSlice";
import EcheanceSlice from "./slices/EcheanceSlice";

// const encryptor = encryptTransform({
//   secretKey: "Super-Secret-key-jrtec",
//   onError: function (error) {
//     // Handle the error.
//   },
// });

const persistConfig = {
  key: "root",
  storage,
  whitelist: [
    "users",
    "categories",
    "accountingFirm",
    "notifications",
    //  "companies",
    "entryImport",
    "navigation",
    "importCompanyFromApi",
  ],
  // transforms: [encryptor]
};

const combinedSupportReducers = combineReducers({
  tickets: TicketSlice,
  documentation: DocumentationSlice,
});

const combinedHistoricReducers = combineReducers({
  company: companyHistoricSlice,
  accountingFirm: accountingFirmHistoricSlice,
  user: userHistoricSlice,
});

const combinedQuestionsReducers = combineReducers({
  questionToValidated: questionToValidatedSlice,
  questionToSend: questionToSendSlice,
  questionsPending: QuestionPendingSlice,
  questionToProcess: QuestionToProcessSlice,
  questionProcessed: QuestionProcessedSlice,
  questionsArchived: questionArchivedSlice,
  answerSuggestions: AnswerSuggestionsSlice,
});

const importCompanyFromApiReducers = combineReducers({
  root: ImportCompanyFromApiSlice,
  quadra: ImportCompanyFromQuadraSlice,
  acd: ImportCompanyFromACDSlice,
});

const notificationReducers = combineReducers({
  company: notificationCompanySlice,
  user: notificationUserSlice,
});

const rootReducer = combineReducers({
  accountingFirm: AccountingFirmSlice,
  categories: CategorySlice,
  companies: CompanySlice,
  echeances: EcheanceSlice,
  entryImport: entryImportSlice,
  entryImportHistory: EntryImportHistorySlice,
  invoices: invoiceSlice,
  importCompanyFromApi: importCompanyFromApiReducers,
  navigation: NavigationSlice,
  notifications: notificationReducers,
  style: styleReducer,
  questions: combinedQuestionsReducers,
  questionExchanges: questionExchangeSlice,
  toasts: toastReducer,
  support: combinedSupportReducers,
  rapidEntryImport: rapidEntrySlice,
  users: userReducer,
  billing: BillingReducer,
  billingOptions: BillingOptionSlice,
  historic: combinedHistoricReducers,
});

export const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  middleware: [thunk],
  reducer: persistedReducer,
  devTools: true,
  // devTools: process.env.NODE_ENV !== "production",
});

export const persistor = persistStore(store);
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
