import { EntryImportType, QuestionType } from "utilities/redux/types";
import { PayloadAction, Slice, createSlice } from "@reduxjs/toolkit";
import { EntryType } from "perf_hooks";

type EntryImportSliceType = {
  imports: EntryImportType[];
  import: EntryImportType | null;
  entries: EntryType[];
  questions: QuestionType[];
};

const initialState: EntryImportSliceType = {
  imports: [],
  import: null,
  entries: [],
  questions: [],
};

export const EntryImportHistorySlice: Slice<EntryImportSliceType> = createSlice({
  name: "entryImportHistoryData",
  initialState,
  reducers: {
    setEntryImportHistories: (state, action: PayloadAction<EntryImportType[]>) => {
      state.imports = action.payload;
    },
    setEntryImportHistory: (state, action: PayloadAction<EntryImportType>) => {
      state.import = action.payload;
    },
  },
});

export const { setEntryImportHistories, setEntryImportHistory } = EntryImportHistorySlice.actions;

export default EntryImportHistorySlice.reducer;
