import { CompanyFromQuadraType, } from "utilities/redux/types";
import { createSlice, PayloadAction, Slice } from "@reduxjs/toolkit";
import { CriteriaType } from "ui/components/inputs";
import { paginateData } from "utilities/tools/array";

export type ImportQuadraCompanyType = {
  //// QUADRA
  companies: CompanyFromQuadraType[] | [];
  allcompanies: CompanyFromQuadraType[] | [];
  companiesSelected: CompanyFromQuadraType[] | [];
  searchItem: CompanyFromQuadraType | null;
  searchTerms: string;
  criteria: CriteriaType | null;
  countPages: number;
  itemsPerPage: number;
  currentPage: number;

  step: number;
};

const initialState: ImportQuadraCompanyType = {
  //// QUADRA STEP
  step: 0,
  // COMPANIES
  allcompanies: [],
  companies: [],
  companiesSelected: [],
  // -> pagination
  itemsPerPage: 50,
  countPages: 1,
  currentPage: 1,

  // SEARCH
  searchTerms: "",
  searchItem: null,
  criteria: null,
};

export const ImportCompanyFromQuadra: Slice<ImportQuadraCompanyType> = createSlice({
  name: "importCompanyQuadraData",
  initialState,
  reducers: {
    ///STEPS
    setStep: (state, action: PayloadAction<number>) => {
      state.step = action.payload;
    },

    /// COMPANIES
    storeCompanies: (state, action: PayloadAction<any[]>) => { },
    storeAllCompanies: (state, action: PayloadAction<CompanyFromQuadraType[]>) => {
      state.allcompanies = action.payload;

      let nonSelectedCompanies = action.payload.filter(
        (item: any) => !state.companiesSelected.some((truc: CompanyFromQuadraType) => truc.Code === item.Code)
      );

      let paginated = paginateData(nonSelectedCompanies, state.itemsPerPage, state.currentPage);

      state.companies = paginated?.paginatedData;
      state.countPages = paginated.nbrOfPages;

      if (paginated.nbrOfPages < state.currentPage) {
        state.currentPage = paginated.nbrOfPages;
      }
    },

    selectCompanies: (state, action: PayloadAction<CompanyFromQuadraType>) => {
      let copy = JSON.parse(JSON.stringify(state.companiesSelected));

      if (state.companiesSelected.some((comp: CompanyFromQuadraType) => comp.Code == action.payload.Code)) {
        ////////////// A. FILTER IT
        let result = copy.filter((item: CompanyFromQuadraType) => item.Code !== action.payload.Code);
        state.companiesSelected = result;
        // and add into companies
        let allComp = JSON.parse(JSON.stringify(state.allcompanies));
        let filtered = allComp.filter((item: any) => !result.some((item2: any) => item2.Code === item.Code));

        let paginated = paginateData(filtered, state.itemsPerPage, state.currentPage);

        state.companies = paginated.paginatedData;
        state.countPages = paginated.nbrOfPages;
      } else {
        /////////////  B. NOT IN ARRAY  ->ADD IT
        let copy2 = JSON.parse(JSON.stringify(state.companiesSelected));
        let newSelected = [...copy2, action.payload];
        state.companiesSelected = newSelected;
        // and remove into companies
        let copyFromCompanies = JSON.parse(JSON.stringify(state.allcompanies));
        let result = copyFromCompanies.filter(
          (item: CompanyFromQuadraType) => !newSelected.some(item2 => item.Code === item2.Code)
        );
        let paginated = paginateData(result, state.itemsPerPage, state.currentPage);

        state.companies = paginated?.paginatedData;
        state.countPages = paginated?.nbrOfPages;
      }
    },
    updateRestrictionCompany: (state, action: PayloadAction<{ code: string; restricted: boolean; }>) => {
      const copy = JSON.parse(JSON.stringify(state.companiesSelected));
      copy.find((item: CompanyFromQuadraType) => item.Code === action.payload.code).restricted =
        action.payload.restricted;
      state.companiesSelected = copy;
    },

    resetQuadraCompanySelected: (state, action: PayloadAction<null>) => {
      state.companiesSelected = [];
      let paginated = paginateData(state.allcompanies, state.itemsPerPage, state.currentPage);

      state.companies = paginated?.paginatedData;
      state.countPages = paginated?.nbrOfPages;
    },
    /// -> PAGINATION

    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
      let paginated = paginateData(state.allcompanies, state.itemsPerPage, action.payload);

      if (state.companies.length == 0) {
        state.currentPage = 1;
      }

      state.companies = paginated.paginatedData;
      state.countPages = paginated.nbrOfPages;
    },
    setCountPages: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },

    ///// SEARCH BY CODE
    setSearchTerm: (state, action: PayloadAction<string>) => {
      state.searchTerms = action.payload;
    },
    setCriteria: (state, action: PayloadAction<CriteriaType>) => {
      state.criteria = action.payload;
    },

    setSearchItem: (state, action: PayloadAction<CompanyFromQuadraType>) => {
      state.searchItem = action.payload;
    },
  },
});

export const {
  storeAllCompanies,
  storeCompanies,
  selectCompanies,
  setStep,
  setCurrentPage,
  setCountPages,
  updateRestrictionCompany,
  resetQuadraCompanySelected,
  setSearchItem,
  setSearchTerm,
  setCriteria,
} = ImportCompanyFromQuadra.actions;

export default ImportCompanyFromQuadra.reducer;
