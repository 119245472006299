import Link from "next/link";

const suggest_faq = [
  {
    question: "A quoi servent les suggestions par défaut ?",
    answer: "",
  },
  {
    question: "Comment ajouter une suggestion ?",
    answer: <>
      <p>Vous pouvez créer une nouvelle suggestion pour un cabinet si vous êtes dirigeant. Pour cela, vous devez vous rendre sur la page <Link className="link" href={`/dashboard/gestion-cabinet/suggestions-reponses`}>Suggestions de réponses</Link>.</p>
      <br />
      <p>Arrivé sur la page, vous devez cliquer sur la catégorie pour laquelle vous voulez ajouter une suggestion et cliquer sur <em>Ajouter une nouvelle suggestion</em>.</p>
      <br />
      <p>N&apos;oubliez pas de cliquer sur le bouton de <em>Créer</em> avant de quitter la page.</p>
    </>,
  },
  {
    question: "Comment modifier une suggestion ?",
    answer: <>
      <p>Vous pouvez modifier une suggestion pour un cabinet si vous êtes dirigeant. Pour cela, vous devez vous rendre sur la page <Link className="link" href={`/dashboard/gestion-cabinet/suggestions-reponses`}>Suggestions de réponses</Link>.</p>
      <br />
      <p>Arrivé sur la page, vous devez cliquer sur la catégorie pour laquelle vous voulez modifier la  suggestion et cliquer sur celle-ci.</p>
      <br />
      <p>N&apos;oubliez pas de cliquer sur le bouton de <em>Modifier</em> avant de quitter la page.</p>
    </>,
  },
  {
    question: "Comment supprimer une suggestion ?",
    answer: <>
      <p>Vous pouvez supprimer la suggestion pour un cabinet si vous êtes dirigeant. Pour cela, vous devez vous rendre sur la page <Link className="link" href={`/dashboard/gestion-cabinet/suggestions-reponses`}>Suggestions de réponses</Link>.</p>
      <br />
      <p>Arrivé sur la page, vous devez cliquer sur la catégorie pour laquelle vous voulez supprimer la suggestion et cliquer sur la croix à coté de la suggestion.</p>
    </>,
  },
];

export default suggest_faq;