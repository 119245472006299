import Link from "next/link";

const relance_faq = [{
  question: "Comment faire une relance pour un dossier ?",
  answer: <>
    <p>Pour faire une relance, vous devez vous rendre sur la page <Link className="link" href={`/dashboard/`}>Dashboard</Link>.</p>
    <p>Vous y trouverez une liste de dossiers avec des <em>Questions en attente de réponses</em>, vous pouvez cliquer sur le dossier pour lequel vous voulez faire une relance. En cliquant sur le bouton <em>Envoyer relance</em> les destinataires recevront un mail de relance et auront également un rappel sur leur interface.</p>
  </>,
}, {
  question: "Le bouton pour envoyer une relance ne fonctionne pas, comment cela se fait-il ?",
  answer: <p>Vous ne pouvez envoyer qu&apos;une seule relance par dossier par jour. Le bouton ne fonctionne plus une fois que vous avez envoyer votre relance journalière.</p>,
},];

export default relance_faq;