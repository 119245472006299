import Link from "next/link";


const importation_faq = [{
  question: "Comment faire une importation ?",
  answer: <>
    <p>Vous pouvez suivre le <Link href={`/informations/tutoriaux/comment-importer-des-donnes-grace-a-un-fec`}>tutoriel</Link>.</p>
    <br />
    <p>Sinon vous pouvez vous rendre sur la page du dossier désiré et cliquer sur l&apos;onglet <em>Importations</em>, puis <em>Nouvelle importation</em> et choisir le connecteur.</p>
  </>
}, {
  question: "Comment retrouver l'historique des importations ?",
  answer: <>
    <p>Vous devez vous rendre sur la page du dossier désiré et cliquer sur l&apos;onglet <em>Importations</em>, puis <em>Historique</em>.</p>
  </>
}, {
  question: "Comment reprendre une importation ?",
  answer: <>
    <p>Vous devez vous rendre sur la page du dossier désiré et cliquer sur l&apos;onglet <em>Importations</em>, puis <em>Historique</em>.</p>
    <br />
    <p>Vous ne pouvez reprendre que la dernière importation en cliquant sur celle-ci.</p>
  </>
},];

export default importation_faq;