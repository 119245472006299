import { createSlice, PayloadAction, Slice } from "@reduxjs/toolkit";
import { CompanyType, NotificationCompanyType } from "utilities/redux/types";

// declaring the types for our state
export type NotificationUserState = {
  subscribedCompanies: CompanyType[];
};

const initialState: NotificationUserState = {
  subscribedCompanies: [],
};

export const notificationCompanySlice: Slice<NotificationUserState> = createSlice({
  name: "notificationCompanyData",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions.
  // In this example, 'increment', 'decrement' and 'incrementByAmount' are actions. They can be triggered from outside this slice, anywhere in the app.
  // So for example, if we make a dispatch to the 'increment' action here from the index page, it will get triggered and change the value of the state from 0 to 1.
  reducers: {
    storeSubscribedCompanies: (state, action: PayloadAction<CompanyType[]>) => {
      state.subscribedCompanies = action.payload;
    },
    addSubscribedCompany: (state, action: PayloadAction<CompanyType>) => {
      let copy = JSON.parse(JSON.stringify(state.subscribedCompanies));
      copy = [...copy, action.payload];
      state.subscribedCompanies = copy;
    },
    removeSubscribedCompany: (state, action: PayloadAction<string>) => {
      let copy = JSON.parse(JSON.stringify(state.subscribedCompanies));
      copy = copy.filter(item => item.slug !== action.payload);
      state.subscribedCompanies = copy;
    },
  },
});

// Here we are just exporting the actions from this slice, so that we can call them anywhere in our app.
export const { storeSubscribedCompanies, addSubscribedCompany, removeSubscribedCompany } =
  notificationCompanySlice.actions;

export default notificationCompanySlice.reducer;
