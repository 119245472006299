export function removeValue(valueToRemove: any, array: any[]) {
  return array.filter((value: any) => value !== valueToRemove);
}

export function objectToArray(object: { [key: string]: string }) {
  return Object.values(object);
}

type PaginationType = number | "prev" | "next";

export function paginateData(data: any[], nbrPerPages: number = 12, currentPage: number = 1) {
  let paginatedData = data.slice(nbrPerPages * (currentPage - 1), nbrPerPages * currentPage);
  let nbrOfPages = Math.ceil(data.length / nbrPerPages);

  return { paginatedData, nbrOfPages };
}

export function rangeArray(currentPage: number, lastPage: number, maxLength: number): PaginationType[] {
  let res: PaginationType[] = [];

  const firstPage = 1;
  const confirmedPagesCount = 3;

  if (lastPage <= maxLength) {
    for (let i = firstPage; i <= lastPage; i++) {
      res.push(i);
    }
  } else {
    const deductedMaxLength = maxLength - confirmedPagesCount;
    const sideLength = deductedMaxLength / 2;

    res.push(firstPage);

    if (currentPage <= sideLength + firstPage) {
      for (let j = firstPage + 1; j <= firstPage + sideLength; j++) {
        res.push(j);
      }
      res.push("next");
    } else if (currentPage > sideLength + firstPage && currentPage < lastPage - sideLength) {
      res.push("prev");
      for (let j = currentPage - sideLength + 1; j <= currentPage + sideLength - 1; j++) {
        res.push(j);
      }
      res.push("next");
    } else if (currentPage >= lastPage - sideLength) {
      res.push("prev");
      for (let j = lastPage - sideLength; j < lastPage; j++) {
        res.push(j);
      }
    }

    res.push(lastPage);
  }

  return res;
}

export const exclusionArrays = (arrA: any[], arrB: any[]): any[] => {
  return arrA.filter((item) => !arrB.includes(item));
};

export const exclusionArraysWithKey = (arr1: any[], arr2: any[], key: string): any[] => {
  const arr2Keys = arr2.map((item) => item[key]); // Récupère les valeurs de la clé spécifiée dans arr2

  return arr1.filter((item) => !arr2Keys.includes(item[key])); // Garde les éléments d'arr1 qui ne sont pas dans arr2
};

export const deDuplicateArray = (array: any[], key: string) => {
  const seen = new Set();
  return array.reduce((unique, item) => {
    const itemKey = key ? item[key] : item;
    if (!seen.has(itemKey)) {
      seen.add(itemKey);
      unique.push(item);
    }
    return unique;
  }, []);
};

export function arrayRotate(arr: Array<any>, count: number = 0) {
  const len = arr.length;
  const newArr = JSON.parse(JSON.stringify(arr));
  newArr.push(...newArr.splice(0, ((count % len) + len) % len));
  return newArr;
}
