const categories_faq =
  [
    {
      question: "Comment changer l'accès d'un salarié à une catégorie ?",
      answer: <p>Pour changer l&apos;accès d&apos;un utilisateur (non dirigeant) à une catégorie, vous devez vous rendre sur la page de gestion des utilisateurs de l&apos;entreprise en question, et sélectionner ou désélectionner les catégories voulues.</p>,
    },
    {
      question: "A quoi servent les catégories par défaut ?",
      answer: <p>Les catégories par défaut servent à l&apos;algorithme pour créer les questions.</p>,
    },
    {
      question: "Comment ajouter une catégorie ?",
      answer: <>
        <p>Vous pouvez créer une nouvelle catégorie pour un dossier. Pour cela, vous devez vous rendre sur la page de l&apos;entreprise en question et cliquer sur l&apos;onglet <em>Paramètres</em> puis <em>Catégories</em>.
        </p>
        <p>Arrivé sur la page, vous pouvez cliquer sur <em>Ajouter une catégorie</em>.</p>
        <br />
        <p>Vous pouvez également ajouter une catégorie directement sur la page des PES à valider en cliquant sur le bouton <em>Ajouter une catégorie</em> situé en dessous des catégories.</p>
        <br />
        <p>Dans les deux cas vous pouvez modifier respectivement le nom et l&apos;icone en cliquant sur le formulaire et l&apos;icone. N&apos;oubliez pas de <strong>cliquer sur le bouton de validation</strong> avant de quitter la page.</p>
      </>,
    },
    {
      question: "Comment modifier une catégorie ?",
      answer: <>
        <p>Vous devez vous rendre sur la page de l&apos;entreprise en question et cliquer sur l&apos;onglet <em>Paramètres</em> puis <em>Catégories</em>.
        </p>
        <p>Vous pouvez modifier respectivement le nom et l&apos;icone en cliquant sur le formulaire et l&apos;icone. N&apos;oubliez pas de <strong>cliquer sur le bouton de validation</strong> avant de quitter la page.</p>
      </>,
    },
    {
      question: "Comment supprimer une catégorie ?",
      answer: <>
        <p>Vous devez vous rendre sur la page de l&apos;entreprise en question et cliquer sur l&apos;onglet <em>Paramètres</em> puis <em>Catégories</em>.
        </p>
        <p>Vous pouvez supprimer seuelemtn les catégories personnalisées en cliquant sur le bouton en forme de poubelle.</p>
      </>,
    },
  ];

export default categories_faq;