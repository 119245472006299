import { createSlice, PayloadAction, Slice } from "@reduxjs/toolkit";

export type EcheanceType = {
  limitStart: string;
  limitEnd: string;
  viewStart: string;
  viewEnd: string;
  //
  jalonWidth: number;
  offsetLeft: number;
  daysPerView: number;
};

const initialState: EcheanceType = {
  limitStart: "01/08/2023",
  limitEnd: "30/08/2023",
  ////
  viewStart: "15/01/2023",
  viewEnd: "25/01/2023",
  //
  jalonWidth: 0,
  offsetLeft: 0,
  daysPerView: 0,
};
declare const document: any;

export const EcheanceSlice: Slice<EcheanceType> = createSlice({
  name: "echeanceData",
  initialState,

  reducers: {
    setLimitDates: (state, action: PayloadAction<{ limitStart: string; limitEnd: string }>) => {
      state.limitStart = action.payload.limitStart;
      state.limitEnd = action.payload.limitEnd;
    },
    setViewDates: (state, action: PayloadAction<{ viewStart: string; viewEnd: string }>) => {
      state.limitStart = action.payload.viewStart;
      state.limitEnd = action.payload.viewEnd;
    },
    ///
    setJalonWidth: (state, action: PayloadAction<number>) => {
      state.jalonWidth = action.payload;
    },
    setOffsetLeft: (state, action: PayloadAction<number>) => {
      state.offsetLeft = action.payload;
    },
    setDaysPerView: (state, action: PayloadAction<number>) => {
      state.daysPerView = action.payload;
    },
  },
});
// Here we are just exporting the actions from this slice, so that we can call them anywhere in our app.
export const { setLimitDates, setViewDates, setJalonWidth, setOffsetLeft, setDaysPerView } = EcheanceSlice.actions;

export default EcheanceSlice.reducer;
