import { AnswerSuggestionType, QuestionCategoryType } from "utilities/redux/types";
import { createSlice, PayloadAction, Slice } from "@reduxjs/toolkit";

// declaring the types for our state
export type AnswerSuggestionsState = {
  defaultSuggestions: AnswerSuggestionType[];
  suggestions: AnswerSuggestionType[];
  categorySelected: QuestionCategoryType | null;
  categories: QuestionCategoryType[];
  suggestionSelected: AnswerSuggestionType | null;
};

const initialState: AnswerSuggestionsState = {
  suggestions: [],
  defaultSuggestions: [],
  suggestionSelected: null,
  categorySelected: null,
  categories: [],
};

export const answerSuggestionsSlice: Slice<AnswerSuggestionsState> = createSlice({
  name: "answerSuggestionsStateData",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions.
  // In this example, 'increment', 'decrement' and 'incrementByAmount' are actions. They can be triggered from outside this slice, anywhere in the app.
  // So for example, if we make a dispatch to the 'increment' action here from the index page, it will get triggered and change the value of the state from 0 to 1.
  reducers: {
    setCategorySelected: (state, action: PayloadAction<QuestionCategoryType>) => {
      state.categorySelected = action.payload;
    },
    setSuggestionSelected: (state, action: PayloadAction<AnswerSuggestionType>) => {
      state.suggestionSelected = action.payload;
    },
    storeSuggestions: (state, action: PayloadAction<AnswerSuggestionType[]>) => {
      state.suggestions = action.payload;
    },
    storeDefaultSuggestions: (state, action: PayloadAction<AnswerSuggestionType[]>) => {
      state.defaultSuggestions = action.payload;
    },
    storeCategories: (state, action: PayloadAction<QuestionCategoryType[]>) => {
      state.categories = action.payload;
    },
    createSuggestion: (state, action: PayloadAction<AnswerSuggestionType>) => {
      let copy = JSON.parse(JSON.stringify(state.suggestions));
      copy = [...copy, action.payload];
      state.suggestions = copy;
    },
    updateSuggestion: (state, action: PayloadAction<AnswerSuggestionType>) => {
      // if(action.payload.id === state.suggestionSelected.id)

      let index = state.suggestions.findIndex(item => item.id === action.payload.id);
      state.suggestions[index] = action.payload;
    },
    deleteSuggestion: (state, action: PayloadAction<AnswerSuggestionType>) => {
      state.suggestionSelected = null;
      let copy = state.suggestions;
      copy = copy.filter(item => item.id != action.payload.id);
      state.suggestions = copy;
    },
  },
});
// Here we are just exporting the actions from this slice, so that we can call them anywhere in our app.
export const {
  storeSuggestions,
  storeDefaultSuggestions,
  setCategorySelected,
  storeCategories,
  setSuggestionSelected,
  createSuggestion,
  updateSuggestion,
  deleteSuggestion,
} = answerSuggestionsSlice.actions;

// calling the above actions would be useless if we could not access the data in the state. So, we use something called a selector which allows us to select a value from the state.
// export const selectCount = (state: RootState) => state.counter.value;

// exporting the reducer here, as we need to add this to the store
export default answerSuggestionsSlice.reducer;
