"use client";

import { PayloadAction, Slice, createSlice } from "@reduxjs/toolkit";
import { CompanyType } from "utilities/redux/types";

export type NavigationSliceType = {
  search: string;
  criteria: string;
  companies: CompanyType[];
  companySelected: CompanyType | null;
  step: "search" | "menu" | "pes";
  prestep: "search" | "menu" | "pes" | "";
  burgerMenuIsOpen: boolean;
};

const initialState: NavigationSliceType = {
  prestep: "",
  step: "search",
  search: "",
  criteria: "",
  companies: [],
  companySelected: null,
  /// burger
  burgerMenuIsOpen: false,
};

export const navigationSlice: Slice<NavigationSliceType> = createSlice({
  name: "navigationData",
  initialState,

  reducers: {
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    setStep: (state, action: PayloadAction<"search" | "menu" | "pes">) => {
      state.prestep = state.step;
      state.step = action.payload;
    },
    setCompanies: (state, action: PayloadAction<CompanyType[]>) => {
      state.companies = action.payload;
    },
    setCompanySelected: (state, action: PayloadAction<CompanyType>) => {
      state.companySelected = action.payload;
    },

    /// BURGER MENU
    setBurgerMenuIsOpen: (state, action: PayloadAction<boolean>) => {
      state.burgerMenuIsOpen = action.payload;
    },
  },
});
// Here we are just exporting the actions from this slice, so that we can call them anywhere in our app.
export const { setSearch, setCompanies, setCompanySelected, setStep, setBurgerMenuIsOpen } = navigationSlice.actions;

export default navigationSlice.reducer;
