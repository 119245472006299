"use client";

import { PayloadAction, Slice, createSlice } from "@reduxjs/toolkit";
import { QuestionExchangeType } from "utilities/redux/types";

export type InvoiceSliceType = {
  invoices: QuestionExchangeType[];
  invoice: QuestionExchangeType | null;
};

const initialState: InvoiceSliceType = {
  invoices: [],
  invoice: null,
};

export const invoiceSlice: Slice<InvoiceSliceType> = createSlice({
  name: "invoiceData",
  initialState,

  reducers: {
    setInvoices: (state, action: PayloadAction<QuestionExchangeType[]>) => {
      state.invoices = action.payload;
    },
    setInvoice: (state, action: PayloadAction<QuestionExchangeType>) => {
      state.invoice = action.payload;
    },
  },
});
// Here we are just exporting the actions from this slice, so that we can call them anywhere in our app.
export const { setInvoices, setInvoice, reset } = invoiceSlice.actions;

export default invoiceSlice.reducer;
