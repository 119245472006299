import { MdMoneyOffCsred } from "ui/react-icons/md";
import { FaMoneyBillTransfer } from "ui/react-icons/fa6";
import { FaFileInvoiceDollar, FaMoneyBillAlt } from "ui/react-icons/fa";
import Link from "next/link";
import { payent_faq } from "../../faq/commercial/payment_faq";
import { tarification_faq } from "../../faq/commercial/tarification_faq";
import { refundFaq } from "../../faq/commercial/refund_faq";
import { demo_faq } from "@/data/faq/commercial/demo_faq";

export const tarification = {
  value: "tarification",
  legend: "Tarification",
  icon: <FaMoneyBillAlt />,
  questions: tarification_faq,
  tuto: ["comment-ajouter-un-moyen-de-paiement", "mettre-a-jour-les-informations-de-moyen-de-paiement"],
};

export const payment = {
  value: "payment",
  legend: "Paiement",
  icon: <FaFileInvoiceDollar />,
  questions: payent_faq,
  tuto: ["comment-ajouter-un-moyen-de-paiement", "mettre-a-jour-les-informations-de-moyen-de-paiement"],
};

export const credit = {
  value: "credit",
  legend: "Remboursement",
  icon: <FaFileInvoiceDollar />,
  questions: refundFaq,
  tuto: [],
};

export const demo = {
  value: "demo",
  legend: "Version démo",
  icon: <MdMoneyOffCsred />,
  questions: demo_faq,
  tuto: [],
};

// ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

const commercial = {
  legend: "Commercial",
  value: "commercial",
  icon: <FaMoneyBillTransfer />,
  items: [tarification, payment, credit, demo],
};

export default commercial;
