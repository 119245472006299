import { IoSettingsSharp } from "ui/react-icons/io5";
import { FaUserFriends } from "ui/react-icons/fa";
import { TbPower } from "ui/react-icons/tb";
import { VscSettings } from "ui/react-icons/vsc";
import { RiUserSettingsLine } from "ui/react-icons/ri";
import { BsBell, BsFileArrowDownFill, BsFillBuildingsFill, BsPlugFill } from "ui/react-icons/bs";
import { MdAddCircle, MdQuestionMark } from "ui/react-icons/md";
import { HiBuildingOffice, HiIdentification } from "ui/react-icons/hi2";
import { GrUpdate } from "ui/react-icons/gr";
import { AiFillTag } from "ui/react-icons/ai";
import { IoIosSend } from "ui/react-icons/io";
import { LuAlarmClock } from "ui/react-icons/lu";
import Acd from "ui/components/icons/svg/Acd";
import Cegid from "ui/components/icons/svg/Cegid";
import Tiime from "ui/components/icons/svg/Tiime";

import categories_faq from "../../faq/technique/categories_faq";
import company_employees_faq from "../../faq/technique/company/company_employees_faq";
import connexion_faq from "../../faq/technique/connexion_faq";
import company_informations_faq from "../../faq/technique/company/company_informations_faq";
import accountingFirm_informations_faq from "../../faq/technique/accountingFirm/accountingFirm_informations_faq";
import accountingFirm_employees_faq from "../../faq/technique/accountingFirm/accountingFirm_employees_faq";
import suggest_faq from "../../faq/technique/suggest_faq";
import cycle_faq from "../../faq/technique/cycle_faq";
import importation_faq from "../../faq/technique/importation_faq";
import send_faq from "../../faq/technique/send_faq";
import relance_faq from "../../faq/technique/relance_faq";
import { user_preference_faq } from "@/data/faq/technique/user_preference_faq";
import { user_notification_faq } from "@/data/faq/technique/user_notification_faq";
import { fec_faq } from "@/data/faq/technique/connector/fec_faq";
import { quadra_faq } from "@/data/faq/technique/connector/quadra_faq";
import { tiime_faq } from "@/data/faq/technique/connector/tiime_faq";
import { acd_faq } from "@/data/faq/technique/connector/acd_faq";
import { company_create_faq } from "@/data/faq/technique/company/company_create_faq";

/////////////////////////////////////////////////////////////////////////////// UTILISATEUR

export const connexion = {
  value: "connexion",
  legend: "Connexion",
  icon: <TbPower />,
  questions: connexion_faq,
  tuto: ["reinitialiser-mot-de-passe"],
};

export const user_preference = {
  value: "user_preference",
  legend: "Préférence utilisateur",
  icon: <RiUserSettingsLine />,
  questions: user_preference_faq,
};

export const user_notification = {
  value: "user_notification",
  legend: "Notification utilisateurs",
  icon: <BsBell />,
  questions: user_notification_faq,
};

const preference = {
  value: "preference",
  legend: "Préférences",
  icon: <VscSettings />,
  items: [user_preference, user_notification],
};

const users = {
  value: "users",
  legend: "Utilisateur",
  icon: <FaUserFriends />,
  items: [connexion, preference],
};

/////////////////////////////////////////////////////////////////////////////// DOSSIER

export const company_create = {
  legend: "Création dossier",
  value: "company_create",
  icon: <MdAddCircle />,
  questions: company_create_faq,
};

export const company_informations = {
  legend: "Informations entreprise",
  value: "company_informations",
  icon: <HiIdentification />,
  questions: company_informations_faq,
};

export const company_employees = {
  value: "company_employees",
  legend: "Salariés",
  icon: <FaUserFriends />,
  questions: company_employees_faq,
};

export const categories = {
  value: "categories",
  legend: "Catégories",
  icon: <AiFillTag />,
  questions: categories_faq,
};

const company = {
  legend: "Dossier / Entreprise",
  value: "company",
  icon: <BsFillBuildingsFill />,
  items: [company_create, company_informations, company_employees, categories],
};

/////////////////////////////////////////////////////////////////////////////// CABINET

export const accountingFirm_informations = {
  legend: "Informations cabinet",
  value: "accountingFirm_informations",
  icon: <HiIdentification />,
  questions: accountingFirm_informations_faq,
};

export const accountingFirm_employees = {
  value: "accountingFirm_employees",
  legend: "Collaborateurs",
  icon: <FaUserFriends />,
  questions: accountingFirm_employees_faq,
  tuto: ["ajout-d-un-nouveau-collaborateur"],
};

export const suggest = {
  value: "suggest",
  legend: "Suggestions de réponses",
  icon: <AiFillTag />,
  questions: suggest_faq,
};

const accountingFirm = {
  legend: "Cabinet comptable",
  value: "accountingFirm",
  icon: <HiBuildingOffice />,
  items: [accountingFirm_informations, accountingFirm_employees, suggest],
};

/////////////////////////////////////////////////////////////////////////////// POINT EN SUSPENS

export const importation = {
  legend: "Importation",
  value: "importation",
  icon: <BsFileArrowDownFill />,
  questions: importation_faq,
  tuto: ["comment-generer-les-points-en-suspens", "comment-importer-des-donnes-grace-a-un-fec", "comment-importer-des-dossier-grace-a-l-api"],
};

export const cycle = {
  legend: "Cycle des questions",
  value: "cycle",
  icon: <GrUpdate />,
  questions: cycle_faq,
  tuto: ["comment-ajouter-une-question-manuellement"],
};

export const send = {
  legend: "Envoi",
  value: "send",
  icon: <IoIosSend />,
  questions: send_faq,
};

export const relance = {
  legend: "Relance",
  value: "relance",
  icon: <LuAlarmClock />,
  questions: relance_faq,
};

const PES = {
  legend: "Points en suspens",
  value: "PES",
  icon: <MdQuestionMark />,
  items: [importation, suggest, categories, cycle, send, relance],
};

/////////////////////////////////////////////////////////////////////////////// CONNECTEURS

export const fec = {
  value: "fec",
  legend: "FEC",
  icon: <BsFileArrowDownFill />,
  questions: fec_faq,
  tuto: ["comment-importer-des-donnes-grace-a-un-fec"],
};

export const quadra = {
  value: "quadra",
  legend: "Quadra",
  icon: <Cegid />,
  questions: quadra_faq,
  tuto: ["comment-ajouter-le-connecteur-Quadra"],
};

export const tiime = {
  value: "tiime",
  legend: "Tiime",
  icon: <Tiime />,
  questions: tiime_faq,
  tuto: ["comment-ajouter-le-connecteur-Tiime"],
};

export const acd = {
  value: "acd",
  legend: "ACD",
  icon: <Acd />,
  questions: acd_faq,
  tuto: ["comment-ajouter-le-connecteur-ACD", "comment-importer-des-dossier-grace-a-l-api-acd", "url-de-connexion-acd"],
};

const connectors = {
  value: "connectors",
  legend: "Connecteurs",
  icon: <BsPlugFill />,
  items: [fec, quadra, tiime, acd],
};

const technique = {
  legend: "Technique",
  value: "technique",
  icon: <IoSettingsSharp />,
  items: [users, company, accountingFirm, PES, connectors],
};

export default technique;
