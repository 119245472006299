export const tarification_faq = [
  {
    question: "Qu'est ce qu'un prix récurrent ?",
    answer: (
      <p>
        C&apos;est un prix que vous allez payer <strong>tous les mois</strong>.
      </p>
    ),
  },
  {
    question: "Qu'est ce que le tarif plancher ?",
    answer: (
      <p>
        C&apos;est le <strong>prix minimum</strong> (hors taxe) que vous allez devoir payer chaque mois, il ne concerne donc en compte que les{" "}
        <strong>prix récurrents</strong>.
      </p>
    ),
  },
  {
    question: "Qu'est ce qu'un prix fixe ?",
    answer: (
      <p>
        C&apos;est un prix que vous ne devez payer qu&apos;<strong>une seule fois</strong>.
      </p>
    ),
  },
  {
    question: "Comment est calculer le prorata ?",
    answer: (
      <p>
        Le prorata est le <strong>pourcentage que vous devez payer</strong> pour votre premier mois facturé.
      </p>
    ),
  },
];
