import { createSlice, PayloadAction, Slice } from "@reduxjs/toolkit";
import { ReportMessageType, ReportTopicType, ReportType, ReportTypeType } from "utilities/redux/types";

export type TicketSlice = {
  window: "home" | "tickets";
  isOpen: boolean;
  tickets: ReportType[];
  ticket: ReportType;
  reportTypes: ReportTypeType[];
  reportTypeSelected: ReportTypeType["value"];
  reportTopics: any[];
  reportTopicSelected: any;
};

const initialState: TicketSlice = {
  window: "home",
  isOpen: false,
  tickets: [],
  ticket: null,
  reportTypes: [],
  reportTopics: [],
  reportTypeSelected: null,
  reportTopicSelected: null,
};

export const StyleSlice: Slice<TicketSlice> = createSlice({
  name: "SupportData",
  initialState,

  reducers: {
    setWindow: (state, action: PayloadAction<"home" | "tickets">) => {
      state.window = action.payload;
    },
    setIsOpen: (state, action: PayloadAction<boolean>) => {
      state.isOpen = action.payload;
    },

    //  TICKETS
    setTickets: (state, action: PayloadAction<ReportType[]>) => {
      state.tickets = action.payload;
    },
    setTicket: (state, action: PayloadAction<ReportType>) => {
      state.ticket = action.payload;
    },
    setReportTypes: (state, action: PayloadAction<ReportTypeType[]>) => {
      state.reportTypes = action.payload;
    },
    setReportTopics: (state, action: PayloadAction<ReportTopicType[]>) => {
      state.reportTopics = action.payload;
    },
    createMessage: (state, action: PayloadAction<ReportMessageType>) => {
      let copy = JSON.parse(JSON.stringify(state.ticket));
      let messages = copy.reportMessages;
      messages = [...messages, action.payload];

      copy.reportMessages = messages;

      state.ticket = copy;
    },
    //  Create report
    setReportTopicSelected: (state, action: PayloadAction<any>) => {
      state.reportTopicSelected = action.payload;
    },
    setReportTypeSelected: (state, action: PayloadAction<ReportTypeType["value"]>) => {
      state.reportTypeSelected = action.payload;
    },
  },
});
// Here we are just exporting the actions from this slice, so that we can call them anywhere in our app.
export const { setWindow, setIsOpen, setTickets, setReportTypes, setReportTopics, setTicket, createMessage, setReportTopicSelected, setReportTypeSelected } =
  StyleSlice.actions;

export default StyleSlice.reducer;
