const company_employees_faq = [
  {
    question: "Comment ajouter un compte utilisateur à un dossier ?",
    answer: <p>Pour ajouter un utilisateur à un dossier, vous devez vous rendre sur la page de l&apos;entreprise en question et aller dans l&apos;onglet paramètres puis utilisateurs.</p>,
  },
  {
    question: "Comment changer le rôle d'un salarié pour un dossier ?",
    answer:
      <>
        <p>Vous devez être dirigeant d&apos;une entreprise ou expert pour pouvoir modifier le rôle d&apos;un utilisateur. Vous devez vous rendre sur la page de gestion des utilisateurs de l&apos;entreprise en question et sélectionner le rôle voulu.</p>
        <p>De plus, vous ne pouvez pas donner le rôle administrateur si vous n&apos;êtes pas vous-même administrateur.</p>
      </>,
  },
  {
    question: "Comment changer l'accès d'un salarié à une catégorie ?",
    answer: <p>Pour changer l&apos;accès d&apos;un utilisateur (non dirigeant) à une catégorie, vous devez vous rendre sur la page de gestion des utilisateurs de l&apos;entreprise en question, et sélectionner ou désélectionner les catégories voulues.</p>,
  },
];

export default company_employees_faq;