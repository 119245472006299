import { AccountingFirmBillingType } from "./../../../../packages/utilities/redux/types";
import { PayloadAction, Slice, createSlice } from "@reduxjs/toolkit";

type BillingDataType = {
  billings: AccountingFirmBillingType[];
  currentBilling: AccountingFirmBillingType | null;
  currentIndex: number;
  isLoading: boolean;
  currentSetupIntent: any;
  invoiceDetails: any;
};
const initialState: BillingDataType = {
  billings: [],
  currentBilling: null,
  currentIndex: new Date("now").getMonth(),
  isLoading: false,
  currentSetupIntent: null,
  invoiceDetails: null,
};

export const BillingSlice: Slice<BillingDataType> = createSlice({
  name: "billingData",
  initialState,
  reducers: {
    setBillings: (
      state,
      action: PayloadAction<AccountingFirmBillingType[]>
    ) => {
      state.billings = action.payload;

      if (action.payload.length > 0) {
        state.currentIndex = 0;
        state.currentBilling = action.payload[0];
      }
    },
    setCurrentBilling: (
      state,
      action: PayloadAction<AccountingFirmBillingType>
    ) => {
      state.currentBilling = action.payload;
    },
    setCurrentIndex: (state, action: PayloadAction<number>) => {
      state.currentIndex = action.payload;
      state.currentBilling = state.billings[action.payload];
    },

    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setSelectedSetupIntent: (state, action: PayloadAction<any>) => {
      state.currentSetupIntent = action.payload;
    },
    setInvoiceDetails: (state, action: PayloadAction<any>) => {
      state.invoiceDetails = action.payload;
    },
  },
});

export const {
  setBillings,
  setCurrentBilling,
  setActualMonthAndYear,
  setCurrentIndex,
  setIsLoading,
  setSelectedSetupIntent,
  setInvoiceDetails,
} = BillingSlice.actions;

export default BillingSlice.reducer;
