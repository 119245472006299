import Link from "next/link";

export const payent_faq = [
  {
    question: "Quels sont les moyens de paiements disponible?",
    answer: (
      <p>
        Pour le moment seul le <strong>prélèvement SEPA</strong> est disponible.
      </p>
    ),
  },
  {
    question: "Comment gérer mon abonnement ?",
    answer: (
      <>
        <p>
          Pour retrouver les détails de votre abonnement, vous devez vous rendre dans l&apos;onglet facturation puis{" "}
          <Link className="link" href={`/dashboard/gestion-cabinet/facturation/mon-abonnement`}>
            Mon abonnement
          </Link>
          .{" "}
        </p>
        <p>Vous y retrouverez vos potentielles remises, votre contrat ainsi que les options auxquelles vous avez souscrit.</p>
      </>
    ),
  },
  {
    question: "Mon cabinet est bloqué, pourquoi ?",
    answer: (
      <p>
        Un cabinet peut être bloqué pour plusieurs raisons. Vous avez terminé votre démo et devez soucrire à un abonnement, ou bien vous avez des factures à
        payer en attente.
      </p>
    ),
  },
  {
    question: "A quoi correspondent les frais de rejet ?",
    answer: (
      <p>
        Si votre RIB n&apos;est pas bien renseigné et qu&apos;un paiement n&apos;pas autorisé, vous serez prélevé d&apos;un montant de frais de rejet de 7,50€.
      </p>
    ),
  },
];
