import { createSlice, PayloadAction, Slice } from "@reduxjs/toolkit";

export type StyleType = {
  darkmode: boolean;
  windowSize: { width: number, height: number; };
};

const initialState: StyleType = {
  darkmode: false,
  windowSize: null
};
declare const document: any;

export const StyleSlice: Slice<StyleType> = createSlice({
  name: "styleData",
  initialState,

  reducers: {
    setDarkmode: (state, action: PayloadAction<"dark" | "light">) => {
      state.darkmode = action.payload === "dark" ? true : false;

      document.documentElement.setAttribute("data-theme", action.payload);
    },
    setWindowSize: (state, action: PayloadAction<{ width: number, height: number; }>) => {
      state.windowSize = action.payload;
    },

    // const MEDIAQUERY = 920
    // const windowSize = useAppSelector(state=>state.style.windowSize)
    // ((!windowSize || windowSize?.width > MEDIAQUERY))
  },
});
// Here we are just exporting the actions from this slice, so that we can call them anywhere in our app.
export const { setDarkmode, setWindowSize } = StyleSlice.actions;

export default StyleSlice.reducer;
